import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debug } from "console";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import DommusRequiredSpan from "../../../../components/DommusRequiredSpan";
import DommusSwitchControl from "../../../../components/DommusSwitchControl";
import { OportunidadeContext } from "../../../../components/GlobalContext/globalContext";
import { cachearContexto } from "../../../../helpers/cachearContexto";
import { OportunidadeAtendimentoVisitaService } from "../../../../services/OportunidadeAtendimentoVisitaService";
import "./index.css";

interface FinalizacaoVisitasEmMassaProps{
  closeModal: () => void;
}

export function FinalizacaoVisitasEmMassa({closeModal}:FinalizacaoVisitasEmMassaProps){
  
  const {register, handleSubmit, control, setValue, getValues, watch, errors} = useForm();
  const [motivosVisitasNaoRealizadas, setMotivosVisitasNaoRealizadas] = useState([] as Array<any>);
  const watchVisitaRealizada = watch('visita_realizada');
  const {visitasNaoFinalizadas, oportunidadeSelecionada, atualizarOportunidade} = useContext(OportunidadeContext);

  useEffect(()=>{
    cachearContexto(motivosVisitasNaoRealizadas, setMotivosVisitasNaoRealizadas, OportunidadeAtendimentoVisitaService.buscarTipos);
  },[])

  const handleOnSubmit = (data:any)=>{
    if(!visitasNaoFinalizadas.length){
      Swal.fire(
        "Ooops...",
        "Não foi possível encontrar visitas em aberto",
        "warning"
      );
      return;
    }

    let payload = {
      id_tipo_oportunidade_atendimento_visita: data?.id_motivo ?? null,
      descricao: data?.descricao,
      visita_realizada: data?.visita_realizada,
      ids_oportunidades_atendimento: visitasNaoFinalizadas
    }

    Swal.fire({
      title: 'Finalização de visitas!',
      html: `Todas as <b>${visitasNaoFinalizadas?.length?.toString().padStart(2, '0')}</b> visitas serão finalizadas com os dados aqui informados!`,
      icon: 'question',
      showDenyButton: true,
      confirmButtonText: 'Ciente, finalizar',
      confirmButtonColor: '#3085d6',
      denyButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
          trackPromise(OportunidadeAtendimentoVisitaService.finalizarEmMassa(payload)).then((response)=>{
            Swal.fire(
              "Sucesso!",
              "Visitas finalizadas com sucesso!!",
              "success"
            );
            atualizarOportunidade(oportunidadeSelecionada?.id_oportunidade ?? 0);
            closeModal();
          }).catch((error)=>{
            Swal.fire(
                "Ooops...",
                "Ocorreu um erro ao tentar finalizar visitas",
                "error"
              )
          })
      }
    })
  }

  return (
     <>
      <Form className="formMotivoVisita" onSubmit={handleSubmit(handleOnSubmit)}>
        <Container fluid>
          <Row>
            <Col sm={12} md={3} className="switch-visita mb-2 align-self-center">
              <Controller
                name="visita_realizada"
                control={control}
                render={({onChange, value})=>(
                  <DommusSwitchControl
                      labelInativo="Visita realizada?"
                      checked={watchVisitaRealizada}
                      tooltip={getValues('visita_realizada') ? 'Sim' : 'Não'}
                      labelAtivo=""
                      onChange={(event:any)=>{
                        setValue('visita_realizada', event?.target?.checked ? 1 : 0)
                      }}
                  />
                )}
              />
            </Col>
            <Col sm={12} md={9}>
              {!watchVisitaRealizada ? (
                <Row>
                  <Form.Group as={Col} controlId="formMotivoVisita">
                    <Form.Label>
                        Motivo: <DommusRequiredSpan />
                    </Form.Label>
                    <Form.Control
                        as="select"
                        ref={register({required: !watchVisitaRealizada})}
                        name="id_motivo"
                    >
                      <option value="">Selecione</option>
                      {motivosVisitasNaoRealizadas?.map((item) => (
                        <option value={item.id_tipo_oportunidade_atendimento_visita} key={item.id_tipo_oportunidade_atendimento_visita}>
                          {item.nome}
                        </option>
                      ))}
                    </Form.Control>    
                    {errors.id_motivo && (
                      <small className="form-text text-danger">
                      Campo Obrigatório*
                      </small>
                    )}
                  </Form.Group>     
                </Row>
                )
                : 
                (<></>)
              }                    
            </Col>
          </Row>
          <Row>
              <Form.Group as={Col} controlId="formDescricao">
                <Form.Label>
                    Descrição: <DommusRequiredSpan />
                </Form.Label>
                <Form.Control
                    type="textarea"
                    as="textarea"
                    ref={register({required: true})}
                    name="descricao"
                    rows={3}
                />
                {errors?.descricao && (
                  <small className="form-text text-danger">
                  Campo Obrigatório*
                  </small>
                )}
              </Form.Group>
          </Row>
        </Container>
      <div className="formFooter mx-0 px-0">
        <Button className="btn" variant="secondary" onClick={closeModal}> 
          Cancelar
        </Button>
        <Button
            type="submit"
            className="btn btn-success"
        >
            <FontAwesomeIcon
                icon={faSave as IconProp}
                style={{ marginRight: "15px" }}
            />
            Salvar
        </Button>
      </div>
    </Form>                
    </>
  )
}
