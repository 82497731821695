import React from 'react';
import './index.css';
import ReactTooltip from "react-tooltip";
import { Form } from 'react-bootstrap';

export default function DommusSwitchControl({onChange, checked, labelAtivo="", tooltip="", labelInativo=""}) {
    return (
        <>
            <ReactTooltip/>
            <Form.Label className="form-switch form-switch-control p-2">
                {labelInativo}
                <Form.Control type="checkbox" checked={checked} onChange={onChange}/>
                <i className="ml-2" data-tip={tooltip}></i>
                {labelAtivo}
            </Form.Label>
        </>
    )
}
