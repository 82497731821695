import crm from "./crm";

const OportunidadeAtendimentoVisitaService = {
    buscarTipos: () => {
        return crm.get('oportunidade/atendimento/visita/tipo');
    },
    salvar: (payload: {}) => {
        return crm.post('oportunidade/atendimento/visita', payload);
    },
    finalizarEmMassa: (payload: {}) => {
        return crm.post('oportunidade/atendimento/visita/finalizar-massa', payload);
    },
};

export { OportunidadeAtendimentoVisitaService };
