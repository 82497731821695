import React, { useEffect, useContext, SetStateAction, useState } from "react";
import { Button, Form, Row, Container, Col, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEraser, faExchangeAlt, faFilter, faTable} from "@fortawesome/free-solid-svg-icons";
import empreendimentoService from "../../services/EmpreendimentoService";
import OportunidadeService from "../../services/OportunidadeService";
import "./index.css";
import guService from "../../services/GuService";
import {cpfMask, apenasNumeros} from "../InputTextMask";
import Swal from "sweetalert2";
import { GerenteType, OperadorType } from '../../pages/EquipesPrePdvSelectIdComponent/types';
import Tabs from "react-bootstrap/cjs/Tabs";
import FiltroAtendimentos from "./FiltroAtendimentos";
import { CampanhaType, MidiaType } from "../../pages/DetalheOportunidade/Cadastro/types";
import { DommusTab } from "../DommusTab";
import { OportunidadeContextFiltersType, fieldBlocoQualificacao } from "../GlobalContext/types";
import { FowardRefFilhoType } from "./types";
import EquipesPrePdv from "../../pages/EquipesPrePdvSelectIdComponent";
import equipeService from "../../services/EquipeService";
import { EmpreendimentoContext, OportunidadeContext } from "../GlobalContext/globalContext";
import { MultiSelect, Option } from "react-multi-select-component";
import formatDommus from "../../helpers/format";
import { HistoricoService } from "../../services/HistoricoService";
import { FiltroQualificacao } from "./FiltroQualificacacao";
import { CamposDinamicosContext } from "../../contexts/CamposDinamicosContext/CamposDinamicosContext";
import { useForm } from "react-hook-form";
import { cachearContexto } from "../../helpers/cachearContexto";
import { StatusOportunidadeContext } from "../../contexts/StatusOportunidadeContext/StatusOportunidadeContext";
import { TipoHistoricoContext } from "../../contexts/TipoHistoricoContext/TipoHistoricoContext";
import DommusSwitch from "../DommusSwitch";
import { FiltroGlobalContext } from './../../contexts/FiltroGlobalContext'
import { TagOportunidadeContext } from "../../contexts/TagOportunidadeContext";
import { CanaisContext } from "../../contexts/CanaisContext";
import ModalDommus from "../Modal";
import { FormBaseDisparoMassa } from "../FormBaseDisparoMassa";

interface FormFiltroPadraoProps {
  submitHandler: (filters: OportunidadeContextFiltersType) => void
  campanhas: Array<CampanhaType>
  midias: Array<MidiaType>
  gerentes: Array<GerenteType>
  operadores: Array<OperadorType>
  exportar: boolean
  dashboard: boolean
  close: () => void | undefined
}

function FormFiltroPadrao({ submitHandler, campanhas, midias, exportar = true, dashboard = false, close }: FormFiltroPadraoProps) {
  const {recarregarCamposDinamicos, recarregarComoConheceu, recarregarLocalidades, recarregarTipologias} = useContext(CamposDinamicosContext)
  const contextoGlobalEmpreendimentos = useContext(EmpreendimentoContext)
  const { niveisInteresse, setNiveisInteresse, tiposDesistencia, setTiposDesistencia, origens } = useContext(OportunidadeContext)
  const { listaStatusOportunidade, setListaStatusOportunidade } = useContext(StatusOportunidadeContext)
  const { tiposHistorico, setTiposHistorico } = useContext(TipoHistoricoContext)
  const {
    campanhaSelecionada, setCampanhaSelecionada,
    midiaSelecionada, setMidiaSelecionada,
    origemSelecionada, setorigemSelecionada,
    tipoHistoricoSelecionado,setTipoHistoricoSeleciondado,
    nomeLead, setNomeLead,
    cpfEnvolvido, setCpfEnvolvido,
    emailLead, setEmailLead,
    telefoneLead, setTelefoneLead,
    empreendimentoSelecionado, setEmpreendimentoSelecionado,
    equipePreAtendimentoSelecionada, setEquipePreAtendimentoSelecionada,
    gerentePreAtendimentoSelecionado, setGerentePreAtendimentoSelecionado,
    usuarioPreAtendimentoSelecionado, setUsuarioPreAtendimentoSelecionado,
    equipePdvSelecionada, setEquipePdvSelecionada,
    gerentePdvSelecionado, setGerentePdvSelecionado,
    usuarioPdvSelecionado, setUsuarioPdvSelecionado,
    idOportunidade, setIdOportunidade,
    tempoSemAtualizacao, setTempoSemAtualizacao,
    dataCadastroOportunidadeInicial, setDataCadastroOportunidadeInicial,
    dataCadastroOportunidadeFinal, setDataCadastroOportunidadeFinal,
    statusOportunidadeSelecionada, setStatusOportunidadeSelecionada,
    motivoDesistencia, setMotivoDesistencia,
    faixaValorInicial, setFaixaValorInicial,
    faixaValorFinal, setFaixaValorFinal,
    interesses, setInteresses,
    dadosEnvolvidoFiltro, setDadosEnvolvidoFiltro,
    modificador1, setModificador1,
    filtroFaixa, setFiltroFaixa,
    tabKey, setTabKey,
    optionsSubstatus, setOptionsSubstatus,
    substatusSelecionado, setSubstatusSelecionado,
    tagsSelecionadas, setTagsSelecionadas,
    dataVendaInicial, setDataVendaInicial,
    dataVendaFinal, setDataVendaFinal,
    dataContabilizacaoInicial, setDataContabilizacaoInicial,
    dataContabilizacaoFinal, setDataContabilizacaoFinal,
    agruparPorLeads, setAgruparPorLeads,
    filtrosQualificacao, setFiltrosQualificacao,
    canaisSelecionados, setCanaisSelecionados,
    limparFiltros, configExibirCanalOPV,
    modificadorCampoEmpreendimento, alterarModificadorEmpreendimento,
    aplicarFiltrosLead, setAplicarFiltrosLead
  } = useContext(FiltroGlobalContext)
  const {listaTagsOportunidade} = useContext(TagOportunidadeContext);
  const { canais, buscarCanais } = useContext(CanaisContext)
  const [ modalDisparoEmMassa, setModalDisparoEmMassa ] = useState(false);
  const [ filtros, setFiltros ] = useState<OportunidadeContextFiltersType>({} as OportunidadeContextFiltersType);
  
  const configsMultiSelect = {
		selectSomeItems: "Selecione",
		allItemsAreSelected: "Todos itens selecionados",
		selectAll: "Selecionar todos",
		search: "Pesquisar",
	}
  const valoresPadroesFormulario = filtrosQualificacao || {
    qualificacaoFiltro: [
      {
        campoDinamicoId: "",
        relacao: "",
        buscaTexto: "",
        inicio: "",
        fim: "",
        multiselect: [],
      },
    ],
  }
  const { register, setValue, getValues, control, reset } = useForm<fieldBlocoQualificacao>({ defaultValues: valoresPadroesFormulario });

  let child: FowardRefFilhoType<typeof EquipesPrePdv>;

  useEffect(() => {
    cachearContexto(contextoGlobalEmpreendimentos.equipes, contextoGlobalEmpreendimentos.setEquipes, equipeService.buscarEquipesSemEmpreendimento)
    cachearContexto(contextoGlobalEmpreendimentos.equipesVisiveis, contextoGlobalEmpreendimentos.setEquipesVisiveis, equipeService.buscarEquipesParaFiltro, true)
    cachearContexto(contextoGlobalEmpreendimentos.gerentes, contextoGlobalEmpreendimentos.setGerentes, equipeService.buscarGerentes)
    cachearContexto(contextoGlobalEmpreendimentos.operadores, contextoGlobalEmpreendimentos.setOperadores, equipeService.buscarOperadores)
    cachearContexto(contextoGlobalEmpreendimentos.empreendimentos, contextoGlobalEmpreendimentos.setEmpreendimentos, empreendimentoService.buscarEmpreendimentos)
    cachearContexto(listaStatusOportunidade, setListaStatusOportunidade, OportunidadeService.buscarListaStatus)
    cachearContexto(niveisInteresse, setNiveisInteresse, OportunidadeService.buscarNivelInteresse)
    cachearContexto(tiposDesistencia, setTiposDesistencia, OportunidadeService.buscarTiposDesistencia)
    cachearContexto(tiposHistorico, setTiposHistorico, HistoricoService.getTipos)
    recarregarCamposDinamicos();
    recarregarComoConheceu(); 
    recarregarLocalidades();
    recarregarTipologias();
    buscarCanais()
  }, []);

  useEffect(() => {
    if (empreendimentoSelecionado && !gerentePdvSelecionado.length && !gerentePreAtendimentoSelecionado.length && !usuarioPdvSelecionado.length && !usuarioPreAtendimentoSelecionado.length) {
      buscaEquipesPorEmpreendimento();
    }
  }, [empreendimentoSelecionado]);

  useEffect(() => {
    if(contextoGlobalEmpreendimentos.equipesVisiveis.length) {
      child?.setStateEquipes(contextoGlobalEmpreendimentos.equipesVisiveis)
    }
  }, [contextoGlobalEmpreendimentos.equipesVisiveis])

  useEffect(() => {
    if(contextoGlobalEmpreendimentos.gerentes.length) {
      child?.setStateGerentes(contextoGlobalEmpreendimentos.gerentes)
    }
  }, [contextoGlobalEmpreendimentos.gerentes])

  useEffect(() => {
    if(contextoGlobalEmpreendimentos.operadores.length) {
      child?.setStateOperadores(contextoGlobalEmpreendimentos.operadores)
    }
  }, [contextoGlobalEmpreendimentos.operadores])

  useEffect(() => {
    if(contextoGlobalEmpreendimentos.empreendimentos.length) {
      montaOptionsEmpreendimentos()
    }
  }, [contextoGlobalEmpreendimentos.empreendimentos])

  function buscaEquipesPorEmpreendimento() {
    setGerentePdvSelecionado([])
    setGerentePreAtendimentoSelecionado([])
    setUsuarioPdvSelecionado([])
    setUsuarioPreAtendimentoSelecionado([])
  }

  function validaCampoData(){
    if(dataCadastroOportunidadeFinal)
    {
      if(!dataCadastroOportunidadeInicial)
      {
        return {bloquear: true, texto:"Data Inicial"};
      }
    }
    if(dataCadastroOportunidadeInicial)
    {
      if(!dataCadastroOportunidadeFinal)
      {
        return {bloquear: true, texto:"Data Final"};
      }
    }
    return {bloquear: false}
  }

  function montaFiltros(exportar: boolean, baseDisparo=false) {
    let tipo_periodo = "";
    let tipo_faixa = "";

    if ( exportar || baseDisparo) {
      if (!empreendimentoSelecionado.length)
      {
            Swal.fire({
              position: 'center',
              icon: "warning",
              title: `Selecione pelo o menos um empreendimento para ${exportar ? "exportar o relatório" : "gerar base de disparo"}`,
              showConfirmButton: true,
            })
            return;
      }
    }

    if(dataCadastroOportunidadeFinal || dataCadastroOportunidadeInicial)
    {
      let retorno = validaCampoData()
      if(retorno.bloquear)
        {
          Swal.fire({
            position: 'center',
            icon: "warning",
            title: "Selecione a " + retorno.texto + "!",
            showConfirmButton: true,
          })
          return;
        }
    }

    tipo_periodo =
      modificador1 === 1
        ? "entrada_lead"
        : modificador1 === 2
        ? "cadastro_opv"
        : modificador1 === 3
        ? "transferencia_opv"
        : modificador1 === 4
        ? "atualizacao_opv"
        : modificador1 === 5
        ? "expiracao_opv"
        : modificador1 === 6
        ? "desistencia_opv"
        : modificador1 === 7
        ? "data_venda"
        : modificador1 === 8
        ? "data_contabilizacao"
        : "";

    tipo_faixa =
      filtroFaixa === 1
        ? "renda"
        : filtroFaixa === 2
        ? "valor_disponivel"
        : filtroFaixa === 3
        ? "sinal"
        : filtroFaixa === 4
        ? "fgts"
        : "";

    let filtro = {
      lead: {
        id_campanha: formatDommus.formataValoresMultiSelect(campanhaSelecionada),
        id_midia: formatDommus.formataValoresMultiSelect(midiaSelecionada),
        id_origem: formatDommus.formataValoresMultiSelect(origemSelecionada),
        nome: nomeLead,
        email: emailLead,
        telefone: telefoneLead,
        tipo_historico: formatDommus.formataValoresMultiSelect(tipoHistoricoSelecionado),
        id_canal: formatDommus.formataValoresMultiSelect(canaisSelecionados)
      },
      oportunidade: {
        id_empreendimento: formatDommus.formataValoresMultiSelect(empreendimentoSelecionado),
        id_equipe_pre_atendimento: formatDommus.formataValoresMultiSelect(equipePreAtendimentoSelecionada),
        id_gerente_pre_atendimento: formatDommus.formataValoresMultiSelect(gerentePreAtendimentoSelecionado),
        id_usuario_pre_atendimento: formatDommus.formataValoresMultiSelect(usuarioPreAtendimentoSelecionado),
        id_equipe_pdv: formatDommus.formataValoresMultiSelect(equipePdvSelecionada),
        id_gerente_pdv: formatDommus.formataValoresMultiSelect(gerentePdvSelecionado),
        id_usuario_atendimento: formatDommus.formataValoresMultiSelect(usuarioPdvSelecionado),
        id_oportunidade: idOportunidade,
        tipo_periodo: tipo_periodo,
        tipo_faixa: tipo_faixa,
        id_nivel_interesse: formatDommus.formataValoresMultiSelect(interesses),
        faixa_inicial: faixaValorInicial ? faixaValorInicial : "",
        faixa_final: faixaValorFinal ? faixaValorFinal : "",
        data_inicial: dataCadastroOportunidadeInicial,
        data_final: dataCadastroOportunidadeFinal,
        data_venda_inicial: dataVendaInicial ? dataVendaInicial : "",
        data_venda_final: dataVendaFinal ? dataVendaFinal : "",
        data_contabilizacao_inicial: dataContabilizacaoInicial ? dataContabilizacaoInicial : "",
        data_contabilizacao_final: dataContabilizacaoFinal ? dataContabilizacaoFinal : "",
        id_status_oportunidade: formatDommus.formataValoresMultiSelect(statusOportunidadeSelecionada),
        id_desistencia_tipo: formatDommus.formataValoresMultiSelect(motivoDesistencia),
        tempoSemAtualizacao: tempoSemAtualizacao ? tempoSemAtualizacao: 0 ,
        id_substatus_oportunidade: formatDommus.formataValoresMultiSelect(substatusSelecionado),
        tags_oportunidade: formatDommus.formataValoresMultiSelect(tagsSelecionadas),
      },
      envolvido: {
        cpf: apenasNumeros(cpfEnvolvido)
      },
      exportar: exportar,      
      qualificacao: getDadosQualificacao(),
      agruparPorLeads: agruparPorLeads ? 'S' : 'N',
      aplicarFiltrosLead: aplicarFiltrosLead ? 'S' : 'N'
    } as OportunidadeContextFiltersType;

    guService.setLocalState("filtros", JSON.stringify(filtro));
    if(baseDisparo){
      setFiltros(filtro);
      setModalDisparoEmMassa(true);
    }else{
      submitHandler(filtro);
      if(close){
        close()
        forcaFecharModal()
      }
    }    
  }

  function forcaFecharModal() {
    const fade = document.querySelector('.fade.modal-backdrop.show');
    if (fade) {
      fade.classList.remove('show');
    }
    const modalBody = document.querySelector('.modal.fade.show');
    if (modalBody) {
      modalBody.classList.remove('show');
    }
  }

  function handleModificaInput1() {
    if (modificador1 < 8) {
      setModificador1(modificador1 + 1);
      return;
    }
    setModificador1(1);
  }

  function handleModificaInputFaixa() {
    if (filtroFaixa < 4) {
      setFiltroFaixa(filtroFaixa + 1);
      return;
    }
    setFiltroFaixa(1);
  }

  function montaOptionsCampanhas() {
    return campanhas.length > 0 ? campanhas.map((campanha) => {
      return {
        label: campanha.nome,
        value: campanha.id_campanha
      }
    }) : []
  }

  function montaOptionsMotivosDesistencia() {
    return tiposDesistencia.map((motivo) => {
      return {
        label: motivo.nome || "",
        value: motivo.id_desistencia_tipo
      }
    })
  }

  function montaOptionsEmpreendimentos() {
    const opcoes = contextoGlobalEmpreendimentos.empreendimentos.map((empreendimento) => {
      return {
        label: empreendimento.nome,
        value: empreendimento.id
      }
    })
    opcoes.unshift({ 
      label: 'Sem Produto',
      value: '0'
    })
    return opcoes
  }

  function montaOptionsTemperatura() {
    return niveisInteresse.map((nivelInteresse) => {
        return {
          label: nivelInteresse.descricao,
          value: nivelInteresse.id_nivel_interesse
        }
      })
  }

  function montaOptionsMidia() {
    return midias.length > 0 ? midias.map((midia) => {
      return {
        label: midia.descricao,
        value: midia.id_midia
      }
    }) : []
  }

  function montaOptionsOrigem() {
    return origens.map((origem) => {
      return {
        label: origem.origem,
        value: origem.id_origem
      }
    })
  }

  function montaOptionTipoHistorico(){
   return tiposHistorico.map(tipo => {
    return {
      label: tipo.descricao,
      value: tipo.id_tipo_historico_lead
      }
   })
  }

  function montaOptionsStatusOportunidades() {
    return listaStatusOportunidade.map((status) => {
      return {
        label: status.nome || "",
        value: status.id_status_oportunidade
      }
    })
  }

  function montarOptionsTagsOportunidade(){
    return listaTagsOportunidade.map((tag)=>{
      return {
        label: tag.nome || "",
        value: tag.id_tag
      }
    })
  }

  function alterarEstadoFiltroEtapa(evento: Option[]) {
    setStatusOportunidadeSelecionada(evento)
    montaOptionsSubstatus(evento, setOptionsSubstatus)
  }

  function montaOptionsSubstatus(evento: Option[], setState: React.Dispatch<SetStateAction<Option[]>>) {
    const etapasFiltradas = listaStatusOportunidade.filter(etapa => evento.map(optionEtapa => optionEtapa.value).includes(etapa.id_status_oportunidade))
    const optionsSubstatusFiltrados = etapasFiltradas.reduce((options: Option[], etapa) => {
      return etapa.substatus?.length ? options.concat(etapa.substatus.map(substatus => {
        return {
          value: substatus.id_substatus_oportunidade,
          label: substatus.nome
        }
      })) : options
    }, [])
    setState(optionsSubstatusFiltrados)
  }



  const getDadosQualificacao = ():(fieldBlocoQualificacao | null) =>{
    let valores = getValues();    
    setFiltrosQualificacao(valores)
    if(valores.qualificacaoFiltro.length === 1 && !valores.qualificacaoFiltro[0]?.campoDinamicoId){
      return null;
    }   

    let regexs = /^(\d{5}-\d{3}|\d{3}\.\d{3}\.\d{3}-\d{2}|\(?\d{2}\)?[-.\s]?\d{4,5}[-.\s]?\d{4}|(R\$|R\$\s?)\d{1,3}(\.\d{3})*,\d{2}|(R\$))|R\$ ,\d{2}|R\$\d+$/;

    valores?.qualificacaoFiltro.map((valores)=>{
      if(valores?.buscaTexto && valores?.buscaTexto?.toString().match(regexs) !== null){
        valores.buscaTexto = apenasNumeros(valores.buscaTexto);
      }
      if(valores?.inicio && valores?.inicio.toString().match(regexs) !== null){
        valores.inicio = apenasNumeros(valores.inicio);
      }
      if(valores?.fim && valores?.fim.toString().match(regexs) !== null){
        valores.fim = apenasNumeros(valores.fim);
      }
      if(valores?.multiselect && valores?.multiselect.length){
        valores.multiselect = valores?.multiselect.map((item)=>{return {"label":item?.value}})
      }
      return valores;
    });

    return valores;
  }

  useEffect(()=>{
    let element = document.getElementById('conteudo-filtro');
    if(element){
      element.style.opacity ="0";
      element.scrollTop = element.scrollHeight;
      setTimeout(()=>{
        if(element){
          element.style.opacity = "1";
        }
      }, 300);
      setTimeout(() => {
        if(element){''
          element.scrollTop = 0;
        }
      }, 500);
    }

  },[])

  function montaOpcoesCanais() {
    return canais.map(canal => {
      return {
        label: canal.nome,
        value: canal.id_canal
      }
    })
  }

  function takeOffInputFocus(){

    const inputs = document.querySelectorAll('input');
    const selects = document.querySelectorAll('select');

    if(inputs){
        inputs.forEach(input => {
            input.blur();
        })
    }


    if(selects){
        selects.forEach(select => {
          select.blur();
        })
    }
  }

  return (
    <>
    <Tabs activeKey={tabKey} onSelect={(key) => setTabKey(key || "oportunidades")} className="mb-0">
      <DommusTab eventKey="oportunidades" title="Oportunidades">
          <Form
            className="formCadastro"
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                e.preventDefault();
                montaFiltros(false);
              }
            }}
          >
          <Container fluid className="container-filtro" id="conteudo-filtro">
          {/* dados da OPV */}
          <fieldset className="form-group">
            <legend>Dados da OPV</legend>
            <Row className="form-first-row">
              <Form.Group as={Col} lg="2" md="6" controlId="formNome">
                <Form.Label>Número da OPV: </Form.Label>
                <Form.Control
                  type="text"
                  value={idOportunidade}
                  onChange={(event: any) => setIdOportunidade(event.target.value)}
                  onBlur={(event:any)=>{
                    if(event.target.value.toString().match(/^[0-9,\s]+$/) === null && event.target.value !== ''){
                      Swal.fire({
                        titleText: "Ooops...",
                        text: 'O campos de "Número da Oportunidade" está com o formato incorreto!',
                        icon: "error",
                        showCloseButton: false,
                        showCancelButton: false,
                        focusConfirm: false,
                        confirmButtonText: "Ok",
                        cancelButtonText: "Não",
                        customClass: {
                          confirmButton: "btn btn-primary mr-3",
                        },
                        buttonsStyling: false,
                      }).then((res) => {
                        if (res.value) {
                          event.target.value = null;                     
                          event.target.focus();                          
                        }
                      });
                    }
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} lg="2" md="6" controlId="formNome">
                <Form.Label id="label-temperatura">Temperatura:</Form.Label>
                <MultiSelect
                  options={montaOptionsTemperatura()}
                  value={interesses}
                  labelledBy="label-temperatura"
                  overrideStrings={configsMultiSelect}
                  onChange={setInteresses}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4" md="6" controlId="formNome">
                <Form.Label id="label-etapa">Etapa da Oportunidade:</Form.Label>
                <MultiSelect
                  options={montaOptionsStatusOportunidades()}
                  value={statusOportunidadeSelecionada}
                  labelledBy="label-etapa"
                  overrideStrings={configsMultiSelect}
                  onChange={alterarEstadoFiltroEtapa}
                />
              </Form.Group>
              <Form.Group as={Col} lg="4" md="6" controlId="formNome">
                <Form.Label id="label-substatus">Status da Oportunidades:</Form.Label>
                <MultiSelect
                  options={optionsSubstatus}
                  value={substatusSelecionado}
                  labelledBy="label-substatus"
                  overrideStrings={configsMultiSelect}
                  onChange={setSubstatusSelecionado}
                />
              </Form.Group>
            </Row>
            <Row>    
              <Form.Group as={Col} lg={dashboard ? 3 : 4} md={dashboard ? 4 : 6} controlId="formNome">
                  <Form.Label id="label-etiquetas">Etiquetas:</Form.Label>
                  <MultiSelect
                    options={montarOptionsTagsOportunidade()}
                    value={tagsSelecionadas}
                    labelledBy="label-etiquetas"
                    overrideStrings={configsMultiSelect}
                    onChange={setTagsSelecionadas}
                  />
              </Form.Group>         
              <Form.Group as={Col} lg={dashboard ? 3 : 4} md={dashboard ? 4 : 6} controlId="formEmpreendimento">
                {modificadorCampoEmpreendimento == 1 ? (<>
                  <Form.Label id="label-empreendimento">Empreendimento(s) da: Oportunidade</Form.Label>
                    <InputGroup style={{ flexWrap: "nowrap" }}>
                      <MultiSelect
                        className="px-0 col-11"
                        options={montaOptionsEmpreendimentos()}
                        value={empreendimentoSelecionado}
                        labelledBy="label-empreendimento"
                        overrideStrings={configsMultiSelect}
                        onChange={setEmpreendimentoSelecionado}
                      />
                      <InputGroup.Append>
                        <Button className="colorBtn btn-alter" onClick={() => alterarModificadorEmpreendimento()} data-tip="Lead">
                          <FontAwesomeIcon icon={faExchangeAlt} />
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>     
                </>) : (<>
                  <Form.Label id="label-empreendimento">Empreendimento(s) do: Lead</Form.Label>
                    <InputGroup style={{ flexWrap: "nowrap" }}>
                      <MultiSelect
                        className="px-0 col-11"
                        options={montaOptionsEmpreendimentos()}
                        value={empreendimentoSelecionado}
                        labelledBy="label-empreendimento"
                        overrideStrings={configsMultiSelect}
                        onChange={setEmpreendimentoSelecionado}
                      />
                      <InputGroup.Append>
                        <Button className="colorBtn btn-alter" onClick={() => alterarModificadorEmpreendimento()} data-tip="Lead">
                          <FontAwesomeIcon icon={faExchangeAlt} />
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>     
                </>)}
              </Form.Group>
              <Form.Group as={Col} lg={dashboard ? 3 : 4} md={dashboard ? 4 : 6} controlId="formMotivosDesistencia">
                <Form.Label id="label-desistencia">Motivo de desistência:</Form.Label>
                <MultiSelect
                  options={montaOptionsMotivosDesistencia()}
                  value={motivoDesistencia}
                  labelledBy="label-desistencia"
                  overrideStrings={configsMultiSelect}
                  onChange={setMotivoDesistencia}
                />
              </Form.Group> 
              {dashboard ? (
              <Form.Group as={Col} lg="3" md="4" controlId="formMotivosDesistencia">
                  <Form.Label id="label-campanha">Campanha:</Form.Label>
                  <MultiSelect
                    options={montaOptionsCampanhas()}
                    value={campanhaSelecionada}
                    labelledBy="label-campanha"
                    overrideStrings={configsMultiSelect}
                    onChange={setCampanhaSelecionada}
                  />
                </Form.Group>): <></>}
                {dashboard ? (
              <Form.Group as={Col} lg="3" md="4"  controlId="formMidia">
              <Form.Label id="label-midia">Midia:</Form.Label>
              <MultiSelect
                options={montaOptionsMidia()}
                value={midiaSelecionada}
                labelledBy="label-midia"
                overrideStrings={configsMultiSelect}
                onChange={setMidiaSelecionada}
              />
            </Form.Group>): <></>}
            </Row>
          </fieldset>
          {/* Lead e Prospect */}
          {!dashboard ? (
            <fieldset className="form-group">
              <legend>Lead e Prospect</legend>
              <Row className="form-first-row">
                <Form.Group as={Col} lg="4" md="6" controlId="formRenda">
                {dadosEnvolvidoFiltro === 1 ? (
                  <>
                    <label>
                      <div className="inputLabel">Dados do envolvido: Nome</div>
                    </label>
                    <InputGroup style={{ flexWrap: "nowrap" }}>
                    <Form.Control
                      type="text"
                      onChange={(event: any) => setNomeLead(event.target.value)}
                      value={nomeLead}
                    />
                      <InputGroup.Append>
                        <Button
                          className="colorBtn btn-alter"
                          onClick={() => {dadosEnvolvidoFiltro == 1 ? setDadosEnvolvidoFiltro(dadosEnvolvidoFiltro+1) : setDadosEnvolvidoFiltro(1)}}
                          data-tip="CPF"
                        >
                          <FontAwesomeIcon icon={faExchangeAlt} />
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </>
                )  : <>
                <label>
                  <div className="inputLabel">Dados do envolvido: CPF</div>
                </label>
                <InputGroup style={{ flexWrap: "nowrap" }}>
                <Form.Control
                  type="text"
                  value={cpfMask(cpfEnvolvido)}
                  onChange={(event: any) => setCpfEnvolvido(cpfMask(event.target.value))}
                />
                  <InputGroup.Append>
                    <Button
                      className="colorBtn"
                      onClick={() => {dadosEnvolvidoFiltro == 1 ? setDadosEnvolvidoFiltro(dadosEnvolvidoFiltro+1) : setDadosEnvolvidoFiltro(1)}}
                      data-tip="Nome"
                    >
                      <FontAwesomeIcon icon={faExchangeAlt} />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </>}
                </Form.Group>

                <Form.Group as={Col} lg="4" md="6" controlId="formNome">
                  <Form.Label>Email:</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(event: any) => setEmailLead(event.target.value)}
                    value={emailLead}
                  />
                </Form.Group>
                <Form.Group as={Col} lg="4" md="6" controlId="formNome">
                  <Form.Label>Telefone:</Form.Label>
                  <Form.Control
                    type="text"
                    value={telefoneLead}
                    onChange={(event: any) => setTelefoneLead(event.target.value)}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} lg={configExibirCanalOPV ? '3' : '4'} md="6" controlId="formNome">
                  <Form.Label id="label-campanha">Campanha:</Form.Label>
                  <MultiSelect
                    options={montaOptionsCampanhas()}
                    value={campanhaSelecionada}
                    labelledBy="label-campanha"
                    overrideStrings={configsMultiSelect}
                    onChange={setCampanhaSelecionada}
                  />
                </Form.Group>
                <Form.Group as={Col} lg={configExibirCanalOPV ? '3' : '4'} md="6" controlId="formMidia">
                  <Form.Label id="label-midia">Midia:</Form.Label>
                  <MultiSelect
                    options={montaOptionsMidia()}
                    value={midiaSelecionada}
                    labelledBy="label-midia"
                    overrideStrings={configsMultiSelect}
                    onChange={setMidiaSelecionada}
                  />
                </Form.Group>
                <Form.Group as={Col} lg={configExibirCanalOPV ? '3' : '4'} md="6" controlId="formNome">
                  <Form.Label id="label-origem">Origem:</Form.Label>
                  <MultiSelect
                    options={montaOptionsOrigem()}
                    value={origemSelecionada}
                    labelledBy="label-origem"
                    overrideStrings={configsMultiSelect}
                    onChange={setorigemSelecionada}
                  />
                </Form.Group>
                {configExibirCanalOPV && (
                  <Form.Group as={Col} lg="3" md="6" controlId="formNome">
                    <Form.Label id="label-canal">Canal:</Form.Label>
                    <MultiSelect options={montaOpcoesCanais()} value={canaisSelecionados} labelledBy="label-canal" overrideStrings={configsMultiSelect} onChange={setCanaisSelecionados} />
                  </Form.Group>
                ) || <></>}
              </Row>
            </fieldset>
          ) : (
            <></>
          )}
           {/* MOVIMENTAÇÃO */}
            <fieldset className="form-group">
              <legend>Movimentação</legend>            
              <Row className="form-first-row">
                <Form.Group as={Col} lg="5" md="12" controlId="formNome">
                  {modificador1 === 1 ? (
                    <>
                      <label>
                        <div className="inputLabel">Período: Entrada do Lead</div>
                      </label>
                      <InputGroup style={{ flexWrap: "nowrap" }}>
                        <Form.Control
                          className="spaceData"
                          type="date"
                          value={dataCadastroOportunidadeInicial}
                          onChange={(event: any) =>
                            setDataCadastroOportunidadeInicial(event.target.value)
                          }
                          />
                        <InputGroup.Append style={{ marginLeft: -3, width: "45%" }}>
                          <Form.Control
                            className="spaceData"
                            type="date"
                            value={dataCadastroOportunidadeFinal}
                            onChange={(event: any) =>
                              setDataCadastroOportunidadeFinal(event.target.value)
                            }
                            />
                        </InputGroup.Append>
                        <InputGroup.Append>
                          <Button
                            className="colorBtn"
                            onClick={() => {
                              handleModificaInput1();
                            }}
                            data-tip="Entrada do Lead"
                          >
                            <FontAwesomeIcon icon={faExchangeAlt} />
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </>
                  ) : modificador1 === 2 ? (
                    <>
                      <label>
                        <div className="inputLabel">Período: Cadastro da OPV</div>
                      </label>
                      <InputGroup style={{ flexWrap: "nowrap" }}>
                        <Form.Control
                          className="spaceData"
                          type="date"
                          value={dataCadastroOportunidadeInicial}
                          onChange={(event: any) =>
                            setDataCadastroOportunidadeInicial(event.target.value)
                          }
                        />
                        <InputGroup.Append style={{ marginLeft: -3, width: "45%" }}>
                          <Form.Control
                            className="spaceData"
                            type="date"
                            value={dataCadastroOportunidadeFinal}
                            onChange={(event: any) =>
                              setDataCadastroOportunidadeFinal(event.target.value)
                            }
                          />
                        </InputGroup.Append>
                        <InputGroup.Append>
                          <Button
                            className="colorBtn"
                            onClick={() => {
                              handleModificaInput1();
                            }}
                            data-tip="Cadastro da OPV"
                          >
                            <FontAwesomeIcon icon={faExchangeAlt} />
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </>
                  ) : modificador1 === 3 ? (
                    <>
                      <label>
                        <div className="inputLabel">
                          Período: Transferência da OPV
                        </div>
                      </label>
                      <InputGroup style={{ flexWrap: "nowrap" }}>
                        <Form.Control
                          className="spaceData"
                          type="date"
                          value={dataCadastroOportunidadeInicial}
                          onChange={(event: any) =>
                            setDataCadastroOportunidadeInicial(event.target.value)
                          }
                        />
                        <InputGroup.Append style={{ marginLeft: -3, width: "45%" }}>
                          <Form.Control
                            className="spaceData"
                            type="date"
                            value={dataCadastroOportunidadeFinal}
                            onChange={(event: any) =>
                              setDataCadastroOportunidadeFinal(event.target.value)
                            }
                          />
                        </InputGroup.Append>
                        <InputGroup.Append>
                          <Button
                            className="colorBtn"
                            onClick={() => {
                              handleModificaInput1();
                            }}
                            data-tip="Transferência da OPV"
                          >
                            <FontAwesomeIcon icon={faExchangeAlt} />
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </>
                  ) : modificador1 === 4 ? (
                    <>
                      <label>
                        <div className="inputLabel">Período: Atualização da OPV</div>
                      </label>
                      <InputGroup style={{ flexWrap: "nowrap" }}>
                        <Form.Control
                          className="spaceData"
                          type="date"
                          value={dataCadastroOportunidadeInicial}
                          onChange={(event: any) =>
                            setDataCadastroOportunidadeInicial(event.target.value)
                          }
                        />
                        <InputGroup.Append style={{ marginLeft: -3, width: "45%" }}>
                          <Form.Control
                            className="spaceData"
                            type="date"
                            value={dataCadastroOportunidadeFinal}
                            onChange={(event: any) =>
                              setDataCadastroOportunidadeFinal(event.target.value)
                            }
                          />
                        </InputGroup.Append>
                        <InputGroup.Append>
                          <Button
                            className="colorBtn"
                            onClick={() => {
                              handleModificaInput1();
                            }}
                            data-tip="de Atualização da OPV"
                          >
                            <FontAwesomeIcon icon={faExchangeAlt} />
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </>
                  ) : modificador1 === 5 ? (
                    <>
                      <label>
                        <div className="inputLabel">Período: Expiração da OPV</div>
                      </label>
                      <InputGroup style={{ flexWrap: "nowrap" }}>
                        <Form.Control
                          className="spaceData"
                          type="date"
                          value={dataCadastroOportunidadeInicial}
                          onChange={(event: any) =>
                            setDataCadastroOportunidadeInicial(event.target.value)
                          }
                        />
                        <InputGroup.Append style={{ marginLeft: -3, width: "45%" }}>
                          <Form.Control
                            type="date"
                            className="spaceData"
                            value={dataCadastroOportunidadeFinal}
                            onChange={(event: any) =>
                              setDataCadastroOportunidadeFinal(event.target.value)
                            }
                          />
                        </InputGroup.Append>
                        <InputGroup.Append>
                          <Button
                            className="colorBtn"
                            onClick={() => {
                              handleModificaInput1();
                            }}
                            data-tip="Expiração da OPV"
                          >
                            <FontAwesomeIcon icon={faExchangeAlt} />
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </>
                  ) : modificador1 === 6 ? (
                    <>
                      <label>
                        <div className="inputLabel">Período: Desistência da OPV</div>
                      </label>
                      <InputGroup style={{ flexWrap: "nowrap" }}>
                        <Form.Control
                          className="spaceData"
                          type="date"
                          value={dataCadastroOportunidadeInicial}
                          onChange={(event: any) =>
                            setDataCadastroOportunidadeInicial(event.target.value)
                          }
                        />
                        <InputGroup.Append style={{ marginLeft: -3, width: "45%" }}>
                          <Form.Control
                            type="date"
                            className="spaceData"
                            value={dataCadastroOportunidadeFinal}
                            onChange={(event: any) =>
                              setDataCadastroOportunidadeFinal(event.target.value)
                            }
                          />
                        </InputGroup.Append>
                        <InputGroup.Append>
                          <Button
                            className="colorBtn"
                            onClick={() => {
                              handleModificaInput1();
                            }}
                            data-tip="Desistência da OPV"
                          >
                            <FontAwesomeIcon icon={faExchangeAlt} />
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </>
                  ) : modificador1 === 7 ? (
                    <>
                      <label>
                        <div className="inputLabel">Período: Data da Venda</div>
                      </label>
                      <InputGroup style={{ flexWrap: "nowrap" }}>
                        <Form.Control
                          className="spaceData"
                          type="date"
                          value={dataVendaInicial}
                          onChange={(event: any) =>
                            setDataVendaInicial(event.target.value)
                          }
                        />
                        <InputGroup.Append style={{ marginLeft: -3, width: "45%" }}>
                          <Form.Control
                            className="spaceData"
                            type="date"
                            value={dataVendaFinal}
                            onChange={(event: any) =>
                              setDataVendaFinal(event.target.value)
                            }
                          />
                        </InputGroup.Append>
                        <InputGroup.Append>
                          <Button
                            className="colorBtn"
                            onClick={() => {
                              handleModificaInput1();
                            }}
                            data-tip="Data da Venda"
                          >
                            <FontAwesomeIcon icon={faExchangeAlt} />
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </>
                  ) : modificador1 === 8 ? (
                    <>
                      <label>
                        <div className="inputLabel">Período: Data da Contabilização</div>
                      </label>
                      <InputGroup style={{ flexWrap: "nowrap" }}>
                        <Form.Control
                          className="spaceData"
                          type="date"
                          value={dataContabilizacaoInicial}
                          onChange={(event: any) =>
                            setDataContabilizacaoInicial(event.target.value)
                          }
                        />
                        <InputGroup.Append style={{ marginLeft: -3, width: "45%" }}>
                          <Form.Control
                            className="spaceData"
                            type="date"
                            value={dataContabilizacaoFinal}
                            onChange={(event: any) =>
                              setDataContabilizacaoFinal(event.target.value)
                            }
                          />
                        </InputGroup.Append>
                        <InputGroup.Append>
                          <Button
                            className="colorBtn"
                            onClick={() => {
                              handleModificaInput1();
                            }}
                            data-tip="Data Contabilização"
                          >
                            <FontAwesomeIcon icon={faExchangeAlt} />
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </>
                  ): (
                    <></>
                  )}
                </Form.Group>

                <Form.Group as={Col} md="6" lg="3" controlId="formDiasSemAtualizar">
                  <Form.Label id="label-origem">Desatualizado há mais de: dias</Form.Label>
                  <Form.Control
                    type="number"
                    onChange={(event: any) => setTempoSemAtualizacao(event.target.value)}
                    placeholder="0"
                    value={tempoSemAtualizacao}
                  />
                </Form.Group>
                <Form.Group as={Col} lg="4" md="6" controlId="formTipoHistorico">
                  <Form.Label id="label-origem">Tipo Histórico:</Form.Label>
                  <MultiSelect
                    options={montaOptionTipoHistorico()}
                    value={tipoHistoricoSelecionado}
                    labelledBy="label-tipo-historico"
                    overrideStrings={configsMultiSelect}
                    onChange={setTipoHistoricoSeleciondado}
                  />
                </Form.Group>
              </Row>
            </fieldset>
            {/* ATENDIMENTO */}
            <fieldset className="form-group">
              <legend>Atendimento</legend>            
              <EquipesPrePdv
                ref={c => child = c}
                equipePdv={equipePdvSelecionada}
                setequipePdv={setEquipePdvSelecionada as any}
                empreendimento=""
                gerentePvd={gerentePdvSelecionado}
                setgerentePvd={setGerentePdvSelecionado as any}
                setusuarioPdv={setUsuarioPdvSelecionado as any}
                equipePre={equipePreAtendimentoSelecionada}
                setequipePre={setEquipePreAtendimentoSelecionada as any}
                gestorPre={gerentePreAtendimentoSelecionado}
                setgestorPre={setGerentePreAtendimentoSelecionado as any}
                setusuarioPre={setUsuarioPreAtendimentoSelecionado as any}
                configuraExibicao="pre_pdv"
                filtro={true}
                equipesPadroes={true}
                gerentesPadroes={true}
                operadoresPadroes={true}
                usuariosPdvMultiSelect={usuarioPdvSelecionado}
                usuariosPreMultiSelect={usuarioPreAtendimentoSelecionado}
              />
            </fieldset>
            {/* QUALIFICAÇÃO */}
            <fieldset className="form-group">
              <legend>Qualificação</legend>            
              <FiltroQualificacao
                control={control}
                register={register}
                setValue={setValue}        
              />
            </fieldset>
          </Container>
          <Container fluid className="footer-filtro">
            <Row>
              <Col lg={4} md={12} className="px-0">
                <div className="d-flex agrupar-opv">
                  <div>
                    <DommusSwitch 
                      label="Leads"
                      checked={agruparPorLeads} 
                      clickEvent={() => setAgruparPorLeads(estadoAtual => !estadoAtual)} 
                      tooltip={agruparPorLeads ? "Agrupar por Oportunidades" : "Agrupar por Leads"}
                      labelDesativado="Agrupar por: Oportunidades"
                      style={{ marginBottom: '-10px' }}
                    />
                    {agruparPorLeads && (<Col className="p-0 my-0 pl-3">
                      <Row className="px-3 py-0 my-0">
                        <Form.Check checked={aplicarFiltrosLead} onChange={() => setAplicarFiltrosLead(estadoAtual => !estadoAtual)}/>
                        <Form.Label className="mt-1" onClick={() => setAplicarFiltrosLead(estadoAtual => !estadoAtual)}>Incluir leads unificados nas OPVs desses leads</Form.Label>
                      </Row>
                    </Col>)}
                  </div>
                </div>
              </Col>
              <Col lg={8} md={12}>
                  <div className="btn-filtro-acao">
                    <Button 
                      type="button" 
                      className="btnFiltroWrapper mr-3 dommus-button" 
                      onClick={() => {
                        montaFiltros(false, true);
                      }}
                    >
                      <FontAwesomeIcon icon={faTable} /> Criar Base Disparo
                    </Button>
                    <Button 
                      type="button" 
                      className="btnFiltroWrapper mr-3 dommus-button" 
                      onClick={() => {
                        limparFiltros('oportunidades')
                        reset()                        
                      }}
                    >
                      <FontAwesomeIcon icon={faEraser} /> Limpar
                    </Button>
                    {exportar ? (
                      <Button
                        className="btnFiltroWrapper"
                        onClick={() => {
                          montaFiltros(true);
                        }}
                        style={{ marginRight: "15px" }}
                        variant={"success"}
                        type={"button"}
                      >
                        <FontAwesomeIcon
                          icon={faDownload}
                          style={{ marginRight: "15px" }}
                        />
                        Exportar
                      </Button>
                    ) : (
                      <></>
                    )}
                    <Button
                      className="btnFiltroWrapper"
                      onClick={() => {
                        takeOffInputFocus()
                        montaFiltros(false)
                      }}
                      variant={"success"}
                      type={"button"}
                    >
                      <FontAwesomeIcon
                        icon={faFilter}
                        style={{ marginRight: "15px" }}
                      />
                      Filtrar
                    </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Form>        
      </DommusTab>
      <DommusTab eventKey="atendimentos" title="Atividades">
        <FiltroAtendimentos
          optionsCampanhas={montaOptionsCampanhas}
          optionsEmpreendimentos={montaOptionsEmpreendimentos}
          optionsMotivosDesistencia={montaOptionsMotivosDesistencia}
          optionsMidia={montaOptionsMidia}
          optionsStatusOportunidade={montaOptionsStatusOportunidades}
          exportFlag={true}
          list={true}
          montaOptionsSubstatus={montaOptionsSubstatus}
        />
      </DommusTab>      
    </Tabs>
    <ModalDommus
        size="md"
        open={modalDisparoEmMassa}
        close={() => setModalDisparoEmMassa(false)}        
        titulo="Criar base disparo em massa"
    >
      <FormBaseDisparoMassa 
        filtros={filtros} 
        closeModal={
          ()=>{
            setModalDisparoEmMassa(false)
            montaFiltros(true);
          }
        }/>
    </ModalDommus>
    </>
  );
}

export default FormFiltroPadrao;
